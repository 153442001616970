import Vue from 'components'
import vuetify from 'modules/vuetify-plugin-without-reset-scss'
// eslint-disable-next-line import/extensions
import SearchBar from 'components/common/SearchBar/SearchBar.vue'
import 'components/common/SearchBar/index.scss'

const el = document.querySelector('.vue-search-bar-mount')

if (el) {
  new Vue({
    vuetify,
    el,
    components: {
      SearchBar,
    },
  })
}
