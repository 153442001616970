import { Location } from 'components/common/SearchBar/types'
import serializeObject from 'utils/executors/serializeObject'

export default function buildSearchUrl({ query, searchUrl, location }: {
  query: string
  searchUrl: string
  location?: Location
}) {
  const queryParams = location
    ? {
      q: query,
      [location.type]: location.name,
    }
    : {
      q: query,
    }

  return `${searchUrl}?${serializeObject(queryParams)}`
}
