import { getGoalsList } from 'utils'

/** @type {{
  viewClinicTab: string
  viewOnlineTab: string
  filteredDoctor: string
  clickSlot: string
  viewAppointmentTelemed: string
  redirectedToTelemedPayment: string
  openScheduleDoctor: string
  chooseAnotherTime: string
}} */
const TOWN_ONLINE_GOALS = getGoalsList({
  block: 'townOnline',
  goals: [
    'viewClinicTab', // Открытие вкладки "В клинике"
    'viewOnlineTab', // Открытие вкладки "Онлайн"
    'filteredDoctor', // Отфильтровал врачей на вкладке "Онлайн"
    'clickSlot', // Редирект по слоту любого врача из вкладки "Онлайн"
    'viewAppointmentTelemed', // Открытие формы записи на Телемед из онлайн-города
    'redirectedToTelemedPayment', // Редирект к оплате Телемеда
    'openScheduleDoctor', // Открыл расписание врача на форме записи
    'chooseAnotherTime', // Выбрал другое время на форме записи
  ],
})

export default TOWN_ONLINE_GOALS
