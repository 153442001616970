<template>
  <div
    class="search-input"
    :class="{
      'search-input_active': isFocused,
      'search-input_gray': !isMobile && !isFocused,
    }"
  >
    <div class="search-input__body">
      <fieldset
        class="search-input__fieldset"
        :class="{
          'search-input__fieldset_no-right': isMobile,
        }"
      />
      <div class="search-input__input-slot">
        <input
          ref="input"
          class="search-input__input ui-text ui-text_body-1"
          type="search"
          :value="value"
          :placeholder="placeholder"
          data-role="none"
          autocomplete="off"
          :readonly="isMobile"
          :maxlength="MAX_QUERY_LENGTH"
          @focus="handleFocus"
          @blur="handleBlur"
          @click="emit('click', $event)"
          @input="handleInput"
        >
        <button
          v-if="value.length > 0"
          type="button"
          class="b-button b-button_text px-0"
          @click="handleClickClear"
        >
          <span class="ui-icon-close-not-a-circle ui-kit-color-icon-secondary" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { MAX_QUERY_LENGTH } from 'components/common/SearchBar/constants'

defineProps<{
  value: string
  placeholder: string
}>()

const emit = defineEmits(['click', 'input', 'focus'])

const isMobile = window.MOBILE_VERSION

const input = ref<HTMLDivElement>()

const isFocused = ref(false)

function handleFocus(event: Event) {
  isFocused.value = true

  emit('focus', event)
}

function handleBlur() {
  isFocused.value = false
}

function handleInput(event: Event) {
  emit('input', (event.target as HTMLInputElement).value)
}

function handleClickClear() {
  emit('input', '')
  input.value?.focus()
}
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

.search-input {
  $block: &;

  display: flex;

  &_gray {
    #{$block}__body {
      background-color: $ui-kit-bg-gray-40;
      border-radius: $border-radius-sm;
    }

    #{$block}__input::placeholder {
      color: $ui-kit-text-info;
    }

    #{$block}__fieldset {
      border: none;
    }
  }

  &_active {
    #{$block}__fieldset {
      border-width: 2px;
      border-color: $ui-kit-primary;
    }

    #{$block}__input {
      caret-color: $ui-kit-primary;
    }
  }

  &__body {
    height: 56px;
    flex: auto;
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
  }

  &__fieldset {
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
    min-inline-size: min-content;
    padding: 0;
    position: absolute;
    inset: 0;
    border-radius: $border-radius-sm;
    border: 1px solid $ui-kit-bg-gray-80;
    pointer-events: none;

    &_no-right {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__input-slot {
    display: flex;
    align-items: center;
    padding: 0 16px;
    flex: 1 1 auto;
    height: 100%;
  }

  &__input {
    height: 100% !important;
    background-color: transparent;
    border-style: none;
    border-radius: 0;
    outline: none;
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0;
    max-width: 100%;
    min-width: 0;
  }
}

/**
 Скопировано из ress.css для страниц, на которых ress.css не подключается
 */
[type="search"] {
  appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; /* Remove the inner padding in Chrome and Safari on macOS */
}

[type="search"]::placeholder {
  font-size: inherit;
}
</style>
