var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "search-input",
      class: {
        "search-input_active": _setup.isFocused,
        "search-input_gray": !_setup.isMobile && !_setup.isFocused,
      },
    },
    [
      _c("div", { staticClass: "search-input__body" }, [
        _c("fieldset", {
          staticClass: "search-input__fieldset",
          class: {
            "search-input__fieldset_no-right": _setup.isMobile,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "search-input__input-slot" }, [
          _c("input", {
            ref: "input",
            staticClass: "search-input__input ui-text ui-text_body-1",
            attrs: {
              type: "search",
              placeholder: _vm.placeholder,
              "data-role": "none",
              autocomplete: "off",
              readonly: _setup.isMobile,
              maxlength: _setup.MAX_QUERY_LENGTH,
            },
            domProps: { value: _vm.value },
            on: {
              focus: _setup.handleFocus,
              blur: _setup.handleBlur,
              click: function ($event) {
                return _setup.emit("click", $event)
              },
              input: _setup.handleInput,
            },
          }),
          _vm._v(" "),
          _vm.value.length > 0
            ? _c(
                "button",
                {
                  staticClass: "b-button b-button_text px-0",
                  attrs: { type: "button" },
                  on: { click: _setup.handleClickClear },
                },
                [
                  _c("span", {
                    staticClass:
                      "ui-icon-close-not-a-circle ui-kit-color-icon-secondary",
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }