<template>
  <div
    class="the-header-line"
    :class="{
      'the-header-line_sticky': isSticky,
      'the-header-line_sticky-active': isSticky && (isStickyActive || isStickyActiveHard),
    }"
  >
    <div class="the-header-line__inner">
      <button
        type="button"
        class="the-header-line__hamburger-btn ui-icon-menu p-3"
        aria-hidden="false"
        data-role="none"
        data-qa="the_header_hamburger_btn"
        @click="handleClickHamburger"
      />
      <a
        href="/"
        class="d-flex"
        data-qa="the_header_logo_link"
      >
        <img
          class="the-header-line__logo"
          :src="siteLogo.src"
          :alt="siteLogo.alt"
        >
      </a>
      <template v-if="isFFElasticsearch">
        <button
          v-if="search.useRender"
          class="the-header-line__search-btn p-3"
          data-role="none"
          @click="handleClickSearchBtn"
        >
          <span class="ui-icon-search" />
        </button>
        <div
          v-else
          class="the-header-line__search-btn-empty"
        />
      </template>
      <template v-else>
        <label
          v-if="search.useRender"
          class="the-header-line__search-btn ui-icon-search p-3"
          data-qa="the_header_search_btn"
        >
          <!-- Инпут используется для хака, который позволяет вызвать виртуальную клавиатуру -->
          <!-- при фокусе, которая будет использовать в модалке с поиском -->
          <input
            type="text"
            class="the-header-line__keyboard-input"
            data-role="none"
            @focus="handleClickSearchBtn"
          >
        </label>
        <div
          v-else
          class="the-header-line__search-btn-empty"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { dispatchCustomEventGlobally, throttle } from 'utils'
import {
  EVENTS as SIDE_MENU_EVENTS,
} from 'components/mobile/SideMenu/constants'
import { DIALOG_MANAGER_EVENT_NAMES } from 'components/common/mixins/DialogManager/constants'
import {
  SITE_LOGO,
  THROTTLE_DELAY,
  OFFSET_HEADER_STICKY,
  HEADER_EVENT_NAMES,
} from 'components/common/TheHeader/constants'

export default {
  name: 'TheHeaderLine',
  props: {
    siteLogo: {
      type: Object,
      default: SITE_LOGO.pd,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isVisibleSearchDialog: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    scrollPrev: 0,
    scrollTopOffset: 10,
    scrollPrevDiff: 0,
    isStickyActiveHard: false,
    isStickyActive: false,
    handleScrollThrottled: () => {},
  }),
  computed: {
    isFFElasticsearch: () => window.FEATURE_FLAGS.pd_elasticsearch,
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollThrottled)
    window.addEventListener(HEADER_EVENT_NAMES.headerStickyHide, this.handleHideStickyHeader)
  },
  created() {
    this.handleScrollThrottled = throttle(this.handleScroll, THROTTLE_DELAY)

    /* Код ниже - костыль, который позволяет выполнить хуки перед включением
        и выключением скролла у <body>, из-за которого скрывается шапка */
    window.addEventListener(DIALOG_MANAGER_EVENT_NAMES.beforeLockEnable, () => {
      this.isStickyActiveHard = window.pageYOffset > OFFSET_HEADER_STICKY
    })

    window.addEventListener(DIALOG_MANAGER_EVENT_NAMES.beforeLockDisable, () => {
      setTimeout(() => {
        this.isStickyActiveHard = false
      }, THROTTLE_DELAY)
    })
  },
  methods: {
    handleClickHamburger() {
      dispatchCustomEventGlobally(SIDE_MENU_EVENTS.open)
    },
    handleClickSearchBtn() {
      this.$emit('header:click-search-btn')
    },
    handleHideStickyHeader() {
      this.isStickyActive = false
    },
    handleScroll() {
      const scrolled = Math.round(window.pageYOffset)

      if (this.isStickyActiveHard) {
        this.isStickyActive = true
        return
      }

      const isScrolledToTop = scrolled <= this.scrollPrev // Если скролл вверх

      if (isScrolledToTop) {
        this.scrollPrevDiff += this.scrollPrev - scrolled // При скролле вверх сохраняется значение в px на которое прокрутили
      } else {
        this.scrollPrevDiff = 0
      }

      const isStickyAllowedTop = this.scrollPrevDiff > this.scrollTopOffset // Если при скролле вверх прокрутили на `${this.scrollTopOffset}px`
      const isEnabledSticky = scrolled > OFFSET_HEADER_STICKY && isStickyAllowedTop // Разрешаем шапке липнуть

      this.isStickyActive = isEnabledSticky && isScrolledToTop
      this.scrollPrev = scrolled
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.the-header-line {
  &_sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    transform: translateY(-105%);
    transition: transform 0.3s ease;

    @include new-elevation-1;

    &-active {
      transform: translateY(0);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    background-color: $ui-kit-bg-gray-0;
  }

  &__hamburger-btn {
    border: none;
    background-color: transparent;
  }

  &__logo {
    object-fit: contain;
    width: 184px;
    height: 28px;
  }

  &__search-btn {
    cursor: pointer;
    position: relative;
    font-size: 24px;
    margin: 0;
  }

  &__search-btn-empty {
    position: relative;
    width: 48px;
  }

  &__keyboard-input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: $font-size-body-1;
    border-width: 0;
    padding: 0;
  }
}
</style>
