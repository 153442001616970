<template>
  <VApp
    class="search-bar"
    data-search-bar
  >
    <form
      class="search-bar__container"
      @submit.prevent="handleSubmitForm"
    >
      <SearchInput
        ref="input"
        v-model="query"
        class="search-bar__input"
        :placeholder="placeholder"
        @click="handleClickSearchInput"
        @focus="handleFocusSearchInput"
        @submit="handleSubmitForm"
        @keydown.native.up.prevent="handleKeydownArrowUp"
        @keydown.native.down.prevent="handleKeydownArrowDown"
      />

      <button
        type="submit"
        class="search-bar__button b-button"
        :class="{
          'ml-4': !isMobile,
          'b-button_appendable': isMobile,
        }"
        data-role="none"
        aria-label="Найти"
      >
        <span class="ui-text ui-text_button ui-kit-color-bg-gray-0 ui-icon-search ui-icon_fz_big" />
      </button>

      <VCard
        v-if="!isMobile && isDialogVisible && query.length >= MIN_QUERY_LENGTH"
        class="search-bar__dropdown"
        rounded
      >
        <SearchResults
          ref="searchResults"
          :query="query"
          :town="town"
          @activate-dropdown-item="handleActivateDropdownItem"
          @deactivate-dropdown-item="handleDeactivateDropdownItem"
        />
      </VCard>
    </form>

    <SearchDialog
      v-if="isMobile"
      v-model="isDialogVisible"
      :town="town"
      :search-url="searchUrl"
      :location="location"
    />
    <VOverlay
      v-else
      v-model="isDialogVisible"
      z-index="1"
      @click="handleClickOverlay"
    />
  </VApp>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { MIN_QUERY_LENGTH } from 'components/common/SearchBar/constants'
import { Location } from 'components/common/SearchBar/types'
import buildSearchUrl from 'components/common/SearchBar/functions/buildSearchUrl'
/* eslint-disable import/extensions */
import SearchInput from 'components/common/SearchBar/components/SearchInput.vue'
import SearchDialog from 'components/common/SearchBar/components/SearchDialog.vue'
import SearchResults from 'components/common/SearchBar/components/SearchResults.vue'
import sendYaGoal from 'components/common/SearchBar/functions/sendYaGoal'
/* eslint-enable import/extensions */

const props = defineProps<{
  placeholder: string
  town: string
  searchUrl: string
  location?: Location
}>()

const isMobile = window.MOBILE_VERSION

const input = ref<InstanceType<typeof SearchInput>>()
const searchResults = ref<InstanceType<typeof SearchResults> & {
  moveUp:() => void
  moveDown: () => void
}>()

const query = ref('')

const isDialogVisible = ref(false)

const dropdownItemLink = ref('')

function handleClickSearchInput() {
  if (isMobile) {
    isDialogVisible.value = true
  }
}

function handleFocusSearchInput() {
  if (!isMobile) {
    isDialogVisible.value = true
    sendYaGoal('search_focus')
  }
}

function handleClickOverlay() {
  isDialogVisible.value = false
}

function handleKeydownArrowUp() {
  searchResults.value?.moveUp()
}

function handleKeydownArrowDown() {
  searchResults.value?.moveDown()
}

function handleActivateDropdownItem({ title, link }: { title?: string, link?: string }) {
  query.value = title ?? ''
  dropdownItemLink.value = link ?? ''
}

function handleDeactivateDropdownItem() {
  dropdownItemLink.value = ''
}

function handleSubmitForm() {
  let redirectUrl = ''

  if (dropdownItemLink.value) {
    sendYaGoal('oldSearch_dropdownItem')
    redirectUrl = dropdownItemLink.value
  } else if (query.value) {
    sendYaGoal('oldSearch_searchButton')
    redirectUrl = buildSearchUrl({
      query: query.value,
      searchUrl: props.searchUrl,
      location: props.location,
    })
  }

  if (redirectUrl) {
    window.location.assign(redirectUrl)
  }
}
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

.search-bar {
  &__container {
    position: relative;
    z-index: 5;
    display: flex;
    padding: 12px;
    margin: -12px;
    border-radius: $border-radius-md;
    background-color: $ui-kit-bg-gray-0;
  }

  &__input {
    flex-grow: 1;
  }

  &__button {
    height: 56px;
    background-color: $ui-kit-secondary;
  }

  &__dropdown {
    position: absolute;
    max-height: 400px;
    left: 0;
    right: 0;
    top: 88px;
    overflow: auto;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-search-bar] {
  @include v-app-reset;
}
</style>
