import { getGoalsList } from 'utils'

const GET_USER_EMAIL_YA_GOALS = getGoalsList({
  block: 'getUserEmail',
  goals: [
    'getUserEmailScreen', // При оставлении отзыва открывается предложение оставить email
    'codeSentToEmail', // Пользователь оставил email (отправлено письмо с подтверждением)
    'checkValidUserCodeSuccess', // Пользователь подтвердил email (прошла валидация)
    'clickDeclineButton', // Пользователь отказался оставлять email (нажал кнопку "отказаться")
    'successScreenWithEmail', // Экран успеха с email
    'clickChangeEmail', // Пользователь нажал "Изменить почту" (изменить адрес)
  ],
})

export default GET_USER_EMAIL_YA_GOALS
