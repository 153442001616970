import { getGoalsList } from 'utils'

const PAGE_NEWRATE_YANDEX_GOALS = getGoalsList({
  block: 'pageNewRate',
  goals: [
    'commonVisitLpu', // Открыли страницу оставления отзыва на клинику
    'commonVisitDoctor', // Открыли страницу оставления отзыва на врача

    'commonSendFormLpu', // Отправились данные из формы отзыва на клинику
    'commonSendFormDoctor', // Отправились данные из формы отзыва на врача

    'commonSeeTwoStepLpu', // Открыли 2й шаг формы на клинику
    'commonSeeTwoStepDoctor', // Открыли 2й шаг формы на врача

    'commonAttachPhotosLpu', // Пользователь приложил минимум одно фото на 2м шаге формы на клинику
    'commonAttachPhotosDoctor', // Пользователь приложил минимум одно фото на 2м шаге формы на врача

    'commonAttachDocumentsLpu', // Пользователь приложил минимум один документ на 3м шаге формы на клинику
    'commonAttachDocumentsDoctor', // Пользователь приложил минимум один документ на 3м шаге формы на врача

    'commonSuccessMessageLpu', // Редирект на сообщение об успешном оставлении отзыва на клинику
    'commonSuccessMessageDoctor', // Редирект на сообщение об успешном оставлении отзыва на врача

    'commonPhoneConfirmed', // Номер подтвержден

    'commonPersonalHistoryModalVisible', // Пользователю отобразилась модалка личной истории
    'commonPersonalHistoryModalVisibleNull', // Пользователю отобразилась модалка личной истории(Новая нейронка, с ответом null - возможно нет личной истории)
    'commonPersonalHistoryModalVisibleFalse', // Пользователю отобразилась модалка личной истории(Новая нейронка, с ответом false - нет личной истории)
    'commonPersonalHistoryModalSkip', // Пользователь нажал "[Пропустить]" в модалке личной истории
    'commonPersonalHistoryModalEditNow', // Пользователь нажал "[Исправить сейчас]" в модалке личной истории

    'commonPersonalHistoryShowAlert', // Пользователю отобразилась подсказка что личная история неправильно заполнена
    'commonPersonalHistoryShowModalInfo', // Пользователь открыл модальное окно с примерами "хороших" личных историй
  ],
})

export default PAGE_NEWRATE_YANDEX_GOALS
