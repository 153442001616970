export const USER_ERRORS = {
  disconnect: {
    title: 'Нет соединения с интернетом',
    text: 'Проверьте соединение и попробуйте снова.',
    closeText: 'Понятно',
    isPersistent: true,
  },
  default: {
    title: 'Произошла ошибка',
    text: 'Обновите страницу и попробуйте ещё раз.',
    closeText: 'Обновить',
    isPersistent: true,
  },
}
