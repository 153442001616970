<template>
  <div
    ref="root"
    class="search-results"
  >
    <div
      v-if="isLoading || !isFirstRequestDone"
      class="search-results__skeletons"
    >
      <div
        v-for="index in 10"
        :key="index"
        class="d-flex py-4 px-6"
      >
        <VSkeletonLoader
          type="avatar"
        />
        <VSkeletonLoader
          class="ml-5"
          type="text"
          width="96%"
        />
      </div>
    </div>
    <div v-else-if="!searchResults.length" />
    <template v-else>
      <div
        v-for="searchResult in searchResultsWithIndexes"
        :key="searchResult.title"
      >
        <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary px-4 pt-4 pb-2">
          {{ searchResult.title }}
        </div>
        <div>
          <a
            v-for="searchResultItem in searchResult.results"
            :key="searchResultItem.link"
            :href="searchResultItem.link"
            class="search-results__result-item"
            :class="{
              'search-results__result-item_active': searchResultItem.index === activeItemIndex,
            }"
            @click="sendYaGoal('oldSearch_dropdownItem')"
          >
            <div class="search-results__image-wrapper">
              <img
                v-if="searchResultItem.image"
                class="search-results__image"
                alt="Фото"
                :src="searchResultItem.image"
                width="40"
                height="40"
              >
              <span
                v-else-if="searchResultItem.icon"
                class="ui-kit-color-icon-secondary"
                :class="ICON_MAP[searchResultItem.icon]"
              />
            </div>
            <div class="ml-4">
              <div
                class="ui-text ui-text_body-1 ui-kit-color-text"
                v-html="highlightQuery(searchResultItem.title)/* eslint-disable-line vue/no-v-html */"
              />
              <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
                {{ searchResultItem.subtitle }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  nextTick,
  ref,
  watch,
} from 'vue'
import apiSearch from 'components/common/SearchBar/api/apiSearch'
import { ApiSearchResponsePost } from 'components/common/SearchBar/api/apiSearch.types'
import { debounce, escapeStringRegexp } from 'utils'
import { MIN_QUERY_LENGTH } from 'components/common/SearchBar/constants'
import sendYaGoal from 'components/common/SearchBar/functions/sendYaGoal'

const props = defineProps<{
  query: string
  town: string
}>()

const emit = defineEmits(['activate-dropdown-item', 'deactivate-dropdown-item'])

const ICON_MAP: {
  // eslint-disable-next-line no-unused-vars
  [key in NonNullable<ApiSearchResponsePost[number]['results'][number]['icon']>]: string
} = {
  speciality: 'ui-icon-doctor',
  lputype: 'ui-icon-clinic',
  diagnostics: 'ui-icon-layers',
  services: 'ui-icon-layers',
  disease: 'ui-icon-disease',
} as const

const root = ref<HTMLDivElement>()

const searchResults = ref<ApiSearchResponsePost>([])
const isLoading = ref(false)
const isFirstRequestDone = ref(false)
const activeItemIndex = ref(0)

const isDropdownItemActivated = ref(false)
const savedQuery = ref('')

const numberOfResults = computed(
  () => searchResults.value.reduce((sum, searchResult) => sum + searchResult.results.length, 0),
)

const searchResultsWithIndexes = computed(() => {
  let index = 1

  return searchResults.value.map(item => ({
    ...item,
    results: item.results.map(resultItem => ({
      ...resultItem,
      index: index++,
    })),
  }))
})

type SearchResultsWithIndexes = typeof searchResultsWithIndexes.value[number]['results'][number]

const activeResultItem = computed<SearchResultsWithIndexes | null>(
  () => {
    let item: SearchResultsWithIndexes | null = null

    searchResultsWithIndexes.value.forEach(
      searchResult => searchResult.results.forEach(
        searchResultItem => {
          if (searchResultItem.index === activeItemIndex.value) {
            item = searchResultItem
          }
        },
      ),
    )

    return item
  },
)

let resultItemDivElements: HTMLDivElement[]

let cancelPreviousRequest = () => {}

const debouncedRequest = debounce(async () => {
  isLoading.value = true

  cancelPreviousRequest()

  let isRequestCancelled = false

  cancelPreviousRequest = () => {
    isRequestCancelled = true
  }

  try {
    const responseData = props.query.length >= MIN_QUERY_LENGTH
      ? (await apiSearch.post({
        query: props.query,
        town: props.town,
      })).data
      : []

    if (!isRequestCancelled) {
      searchResults.value = responseData
    }
  } catch { /* empty */ } finally {
    isLoading.value = false
    isFirstRequestDone.value = true
  }
}, 300)

function scroll(top: number) {
  if (root.value) {
    root.value.parentElement?.scroll({
      top,
      behavior: 'smooth',
    })
  }
}

function deactivateDropdownItem() {
  activeItemIndex.value = 0
  scroll(0)
  emit('deactivate-dropdown-item')
}

watch(() => props.query, () => {
  if (!isDropdownItemActivated.value) {
    debouncedRequest()
    savedQuery.value = props.query
    deactivateDropdownItem()
  }

  isDropdownItemActivated.value = false
}, { immediate: true })

watch(searchResults, () => {
  nextTick(() => {
    resultItemDivElements = root.value
      ? Array.from(root.value.querySelectorAll('.search-results__result-item'))
      : []
  })
})

function highlightQuery(text: string) {
  return text.replace(
    new RegExp(`(${escapeStringRegexp(savedQuery.value).trim()})`, 'ig'),
    '<span class="ui-text ui-text_subtitle-1 ui-kit-color-primary">$1</span>',
  )
}

function handleActivateItem() {
  if (activeItemIndex.value === 0) {
    deactivateDropdownItem()
    return
  }

  isDropdownItemActivated.value = true
  emit('activate-dropdown-item', { title: activeResultItem.value?.title, link: activeResultItem.value?.link })

  const element = resultItemDivElements[activeItemIndex.value - 1]

  if (element) {
    scroll(element.offsetTop - element.offsetHeight)
  }
}

defineExpose({
  moveDown() {
    if (activeItemIndex.value < numberOfResults.value) {
      activeItemIndex.value++
      handleActivateItem()
    }
  },
  moveUp() {
    if (activeItemIndex.value > 0) {
      activeItemIndex.value--
      handleActivateItem()
    }
  },
})
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

.search-results {
  &__image-wrapper {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: $border-radius-md;
  }

  &__image {
    border: 1px solid $ui-kit-bg-gray-60;
    border-radius: $border-radius-md;
    object-fit: contain;
  }

  &__skeletons {
    :deep(.v-skeleton-loader__avatar) {
      width: 16px;
      height: 16px;
    }
  }

  &__result-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-decoration: none;

    &:hover,
    &_active {
      background-color: $ui-kit-bg-gray-40;
    }
  }
}
</style>
