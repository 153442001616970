var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "root", staticClass: "search-results" },
    [
      _setup.isLoading || !_setup.isFirstRequestDone
        ? _c(
            "div",
            { staticClass: "search-results__skeletons" },
            _vm._l(10, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "d-flex py-4 px-6" },
                [
                  _c("VSkeletonLoader", { attrs: { type: "avatar" } }),
                  _vm._v(" "),
                  _c("VSkeletonLoader", {
                    staticClass: "ml-5",
                    attrs: { type: "text", width: "96%" },
                  }),
                ],
                1
              )
            }),
            0
          )
        : !_setup.searchResults.length
        ? _c("div")
        : _vm._l(_setup.searchResultsWithIndexes, function (searchResult) {
            return _c("div", { key: searchResult.title }, [
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-2 ui-kit-color-text-secondary px-4 pt-4 pb-2",
                },
                [_vm._v("\n        " + _vm._s(searchResult.title) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(searchResult.results, function (searchResultItem) {
                  return _c(
                    "a",
                    {
                      key: searchResultItem.link,
                      staticClass: "search-results__result-item",
                      class: {
                        "search-results__result-item_active":
                          searchResultItem.index === _setup.activeItemIndex,
                      },
                      attrs: { href: searchResultItem.link },
                      on: {
                        click: function ($event) {
                          return _setup.sendYaGoal("oldSearch_dropdownItem")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "search-results__image-wrapper" },
                        [
                          searchResultItem.image
                            ? _c("img", {
                                staticClass: "search-results__image",
                                attrs: {
                                  alt: "Фото",
                                  src: searchResultItem.image,
                                  width: "40",
                                  height: "40",
                                },
                              })
                            : searchResultItem.icon
                            ? _c("span", {
                                staticClass: "ui-kit-color-icon-secondary",
                                class: _setup.ICON_MAP[searchResultItem.icon],
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c("div", {
                          staticClass:
                            "ui-text ui-text_body-1 ui-kit-color-text",
                          domProps: {
                            innerHTML: _vm._s(
                              _setup.highlightQuery(
                                searchResultItem.title
                              ) /* eslint-disable-line vue/no-v-html */
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_body-2 ui-kit-color-text-secondary",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(searchResultItem.subtitle) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }