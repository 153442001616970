import Vue from 'components'
import Vuetify from 'vuetify/lib/framework'
import * as directives from 'vuetify/lib/directives'
import { ru } from 'vuetify/lib/locale'
import styleVars from 'www/themes/doctors/common/variables-export.module.scss'

Vue.use(Vuetify, {
  directives,
})

const opts = {
  lang: {
    current: 'ru',
    locales: { ru },
  },
  theme: {
    options: {
      variations: false,
    },
    themes: {
      light: {
        // Текст, основные
        uiKitText: styleVars.uiKitText,
        uiKitTextSecondary: styleVars.uiKitTextSecondary,
        uiKitTextInfo: styleVars.uiKitTextInfo,

        // Текст, дополнительные
        uiKitTextSuccess: styleVars.uiKitTextSuccess,
        uiKitTextError: styleVars.uiKitTextError,
        uiKitTextWarning: styleVars.uiKitTextWarning,
        uiKitDisabled: styleVars.uiKitDisabled,

        // Иконки
        uiKitIconPrimary: styleVars.uiKitIconPrimary,
        uiKitIconSecondary: styleVars.uiKitIconSecondary,
        uiKitIconInfo: styleVars.uiKitIconInfo,

        // Цвета бренда
        uiKitBrand: styleVars.uiKitBrand,
        uiKitSecondary: styleVars.uiKitSecondary,
        uiKitSpecial: styleVars.uiKitSpecial,

        // Цвета состояний + вспомогательные
        primary: styleVars.uiKitPrimary, // Нужно использовать имя primary, т.к. есть селекторы завязанные под него
        uiKitSuccess: styleVars.uiKitSuccess,
        uiKitError: styleVars.uiKitError,
        uiKitWarning: styleVars.uiKitWarning,

        // Фоны + вспомогательные цвета
        uiKitBgPrimary: styleVars.uiKitBgPrimary,
        uiKitBgSecondary: styleVars.uiKitBgSecondary,
        uiKitBgSpecial: styleVars.uiKitBgSpecial,
        uiKitBgSuccess: styleVars.uiKitBgSuccess,
        uiKitBgError: styleVars.uiKitBgError,
        uiKitBgWarning: styleVars.uiKitBgWarning,
        uiKitBgClub: styleVars.uiKitBgClub,

        uiKitButtonTextSnackbars: styleVars.uiKitButtonTextSnackbars,
        uiKitRating: styleVars.uiKitRating,

        uiKitBgGray0: styleVars.uiKitBgGray0,
        uiKitBgGray40: styleVars.uiKitBgGray40,
        uiKitBgGray60: styleVars.uiKitBgGray60,
        uiKitBgGray80: styleVars.uiKitBgGray80,

        // Прочие цвета
        gold: styleVars.gold,
        anchor: 'none',
      },
    },
  },
  icons: {
    iconfont: 'ui-icon',
    values: {
      next: 'ui-icon-arrow-right ui-icon_fz_smaller',
      prev: 'ui-icon-arrow-left ui-icon_fz_smaller',
      radioOn: 'ui-icon-radio-on',
      radioOff: 'ui-icon-radio-off',
      checkboxOn: 'ui-icon-checkbox-on',
      checkboxOff: 'ui-icon-checkbox-off',
      closeNotCircle: 'ui-icon-close-not-a-circle',
      emailIcon: 'ui-icon-at-sign',
      arrowDown: 'ui-icon-arrow-down',
    },
  },
}

export default new Vuetify(opts)
